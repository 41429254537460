.container {
  position: relative;
}

.app {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
}

.content {
  flex: 1;
  margin: 24px;
}
