$top-bar-background-color: rgba(185,219,240,.35);
$top-bar-language-link-color: #245578;
$top-bar-language-link-hover-color: #245578;

.top-bar {
  display: flex;
  align-items: center;
  background-color: $top-bar-background-color;

  .languages {
    margin-left: auto;
    margin-right: 24px;
  }

  .language-button {
    color: $top-bar-language-link-color;
    cursor: pointer;

    &:hover,
    &:active {
      color: $top-bar-language-link-hover-color;
    }
  }
}
