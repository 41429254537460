.footer {
  display: flex;
  margin-bottom: 32px;
  margin-left: 32px;
  margin-right: 32px;
}

.developer {
  margin-left: auto;
}
